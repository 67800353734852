<template>
  <div>
    <title-nav :title="'공지'" :nav="'CS / 메일 발송'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <b-form-group>
            <b-input-group prepend="발송 대상" class="w-100">
              <b-form-select v-model="target_type" :options="options" />
              <template v-if="target_type == 'parts'">
                <b-form-input
                  v-model="emails"
                  :state="isValidEmails"
                  placeholder="support@wemari.co.kr,kej@innosalt.com ..."
                  pattern="(\d{10,}(,)*)+"
                  aria-required="메일주소 입력"
                  class="w-75"
                  required
                ></b-form-input>
              </template>
            </b-input-group>
          </b-form-group>

          <template v-if="words && words.length > 0">
            <b-form-group v-for="(item, index) in words" :key="index">
              <b-input-group>
                <b-form-input v-model="item.key" placeholder="{EMAIL}" class="col-2" />
                <b-form-input
                  v-model="item.value"
                  placeholder="support@wemari.co.kr,kej@innosalt.com"
                />
              </b-input-group>
            </b-form-group>
          </template>

          <b-form-group>
            <b-input-group prepend="발송 시점">
              <b-form-select v-model="isReserved" :options="isReservedOpt" />
              <template v-if="isReserved === 'true'">
                <b-form-datepicker id="datepicker-1" v-model="reservedDay" size="sm" locale="kr" />
                <b-form-timepicker id="timepicker-1" v-model="reservedTime" size="sm" locale="kr" />
              </template>
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group prepend="제목">
              <b-form-input v-model="subject" placeholder="(광고)...." required />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-form-textarea
              v-model="contents"
              id="contents"
              placeholder="내용을 입력하세요"
              rows="10"
              max-rows="10"
              required
              wrap="hard"
            />
            <p>
              <span> 발송 대상이 일부인 경우 대체 텍스트 적용이 가능합니다.<br /> </span>
              - 내용 중 대체할 문구는 {KEY} 와 같이 입력해주세요. 예) 안녕하세요. {EMAIL}님<br />
              - 대체할 텍스트는 발송 대상의 갯수 및 순서가 동일해야 합니다. (콤마로 구분)<br />
            </p>
          </b-form-group>
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <b-button @click="onReset()" class="m-1" variant="">
              취소
            </b-button>
            <b-button @click="submitEmail()" class="m-1" variant="primary">
              전송
            </b-button>
          </div>
          <b-button
            @click="words.push({ key: '', value: '' })"
            class="m-1"
            variant="info"
            v-if="target_type == 'parts'"
          >
            대체 텍스트 추가
          </b-button>
        </div>
      </div>
    </b-container>
    <!-- 삭제 모달창 -->
  </div>
</template>

<script>
import { sendMail } from '@/api/cs'
export default {
  data() {
    return {
      options: [
        { value: 'all', text: '전체' },
        { value: 'market', text: '마케팅 동의자' },
        { value: 'parts', text: '일부' }
      ],
      isReservedOpt: [
        { value: 'true', text: '예약 전송' },
        { value: 'false', text: '즉시 전송' }
      ],

      contents: '',
      subject: '',
      target_type: 'all', // all, market, parts 발송대상
      emails: '',
      isReserved: false,
      reservedDay: null,
      reservedTime: null,
      words: []
    }
  },
  computed: {
    isValidEmails() {
      const regex = new RegExp(
        /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/g
      )
      let isValid = regex.test(this.emails)
      return isValid
    }
  },
  methods: {
    onReset() {
      this.subject = null
      this.contents = null
      this.reservedDay = null
      this.reservedTime = null
      this.emails = null
      this.target_type = 'all'
      this.words = []
    },
    async submitEmail() {
      // yyyy-mm-dd hh:mm
      let dt = this.isReserved ? `${this.reservedDay} ${this.reservedTime}` : null
      // 전체전송
      if (this.target_type !== 'parts') {
        if (this.target_type && this.contents && this.subject) {
          const response = await sendMail(this.target_type, this.contents, this.subject, dt)
          if (response.code != '0000') {
            console.log(`error: ${response.data.code}, msg:${response.data.message}`)
          } else {
            alert('전송 성공')
            this.onReset()
          }
        } else {
          alert('내용, 제목 모두 입력해주세요')
        }
      } else {
        if (this.emails && this.target_type && this.contents && this.subject) {
          const response = await sendMail(
            this.target_type,
            this.contents,
            this.subject,
            dt,
            this.emails,
            this.words
          )
          if (response.code != '0000') {
            console.log(`error: ${response.data.code}, msg:${response.data.message}`)
          } else {
            alert('전송 성공')
            this.onReset()
          }
        } else {
          alert('휴대폰 번호, 내용, 제목 모두 입력해주세요')
        }
      }
    }
  },
  mounted() {}
}
</script>

<style scoped>
table tbody tr .td-title {
  color: #fff;
  background-color: #212529;
  border-color: #383f45;
  padding-left: 10px;
}

table tbody tr .td-data {
  color: #000;
  background-color: #ffffff;
  border-color: #383f45;
  text-align: left;
  padding-left: 10px;
}
</style>
